import {computed, onBeforeMount, ref} from "vue"
import {useAppUtils} from "@/composables/useAppUtils"
import {useStore} from "vuex"

let loadingCKEditorInitiated = false
const isCKEditorAvailable = ref(false)

export function useCKEditor () {

  //computed(() => !!(window['CKEDITOR'] && window['CKEDITOR']['DecoupledEditor']))

  const { loadScript, getUrlRoot, get2SymbolsLanguage } = useAppUtils()
  const store = useStore()

  const editorLanguage = get2SymbolsLanguage()

  onBeforeMount(() => {
    if (!isCKEditorAvailable.value && !loadingCKEditorInitiated) {
      loadingCKEditorInitiated = true


      const editorLang = editorLanguage.value
      console.log('useCKEditor siteLang', editorLang)

      // https://cdn.ckeditor.com/ckeditor5/35.1.0/super-build/translations/ru.js

      const urlRoot = getUrlRoot()
      const fileVersionForAntiCahce = '20241010'
      const loadScriptPromises = [
        //loadScript('https://cdn.ckeditor.com/ckeditor5/35.1.0/super-build/ckeditor.js')
        loadScript(urlRoot + `libs/ckeditor/ckeditor.js?v=${fileVersionForAntiCahce}`)
      ]
      if (editorLang !== 'en') {
        //loadScriptPromises.push(loadScript(`https://cdn.ckeditor.com/ckeditor5/35.1.0/super-build/translations/${editorLang}.js`))
        loadScriptPromises.push(loadScript(urlRoot + `libs/ckeditor/translations/${editorLang}.js?v=${fileVersionForAntiCahce}`))
      }

      Promise.all(loadScriptPromises).then( data  => {
        console.log("Script loaded successfully", data);
        isCKEditorAvailable.value = true
      }).catch(reason => {
        console.log('Loading CKEditor error', reason)
      })

      // const scriptElement = document.createElement('script')
      // scriptElement.src = 'https://cdn.ckeditor.com/ckeditor5/35.1.0/super-build/ckeditor.js'
      // scriptElement.onload = () => { isCKEditorAvailable.value = true }
      // document.head.appendChild(scriptElement)
    }
  })

  return {
    isCKEditorAvailable,
    editorLanguage,
    //getEditorLanguage,
  }

}
